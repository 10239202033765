import React, { useState, useRef, useEffect } from 'react'
import AuthLayout from '../../layout/Auth'
import { NavLink, useNavigate } from 'react-router-dom'
import countryCode from '../../utils/countryCode'
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidation } from '../../utils/form_validation';
import { useMutation } from 'react-query';
import Client from '../../client'
import { toast } from 'react-toastify';
import ButtonLoader from '../../component/ButtonLoader';
import Cookies from 'js-cookie';
import showPasswordIcon from '../../assets/img/showPassword.svg'
import s1 from '../../assets/img/s1.svg'
import s2 from '../../assets/img/s2.svg'
import s3 from '../../assets/img/s3.svg'
import logo from '../../assets/img/newLogo.svg'
import { Row, Col } from 'reactstrap'
import Select from 'react-select';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login'
import { isLoadedFromAyoba } from '../../utils/Ayobacheck';

import { getDeviceSignature } from '../../utils/deviceSignature';



const Login = () => {
    const ayobaCheck = isLoadedFromAyoba()
    const fb_access_token = useRef(null)

    const [device_signature, setDeviceSignature] = useState(null)


    const fetchData = async () => {
        try {
            const signature = await getDeviceSignature();
            setDeviceSignature(signature);
        } catch (error) {
            console.error('Error fetching device signature:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])


    const { mutate: storeSignature, isLoading: storing } = useMutation(Client.auth.store_device_signature, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                const intent_url = localStorage.getItem('genti_intent_url')
                if (intent_url) {
                    navigate(intent_url)
                } else {
                    navigate('/')
                }
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })


    const { register, handleSubmit, formState: { errors }, control } = useForm({
        resolver: yupResolver(registerValidation),
    });

    const [userCallingCode, setUserCallingCode] = useState(null)

    const getUserCountry = async () => {
        const data = await axios.get(`https://api.ipregistry.co/?key=${process.env.REACT_APP_LOCATION_KEY}`)

        // return data.data.location.country.calling_code
        setUserCallingCode(data.data.location.country.calling_code)

    }

    useQuery(['user-country'], () => { getUserCountry() })

    const { field: { value: gender, onChange: typeOnChange, ...restTypeField } } = useController({ name: 'gender', control });

    const types = [{
        title: '0-15',
        id: '1'

    }, {
        title: '16-30',
        id: '2'

    }]
    const genderOptions = [{
        title: 'Male',
        id: '1'

    }, {
        title: 'Female',
        id: '2'

    }]
    const [showPassword, setShowPassword] = useState(false)
    const togglePassword = () => {
        setShowPassword(!showPassword)
    }




    const { mutate: registerUser, isLoading } = useMutation(Client.auth.register, {
        onSuccess: async (data) => {
            // reset();
            navigate('/auth/OTP')

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            if (error.data) return toast.error(error.data.message)
            else toast.error(error.message)

        }
    })

    const onSubmitHandler = (data) => {
        Cookies.set("GENTI_NEW_USER_EMAIL", data.email)
        data.country_id = `+${userCallingCode}`;
        data.mobile_number = ''
        registerUser({ ...data, isRegister: 0, version: 'web' })
    };



    const { mutate: loginWithGoogle, isLoading: is_loading } = useMutation(Client.auth.google_login, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                Cookies.set("GENTI_APP_TOKEN", data.access_token)
                Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.user_detail))
                storeSignature({
                    device_signature
                })
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })


    const { mutate: getGoogleDetails, isLoading: loading } =
        useMutation((token) => axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        }),
            {
                onSuccess: (data, variables, context) => {
                    const { name, email } = data.data
                    const payload = { name, email, access_token: variables, country_id: `${userCallingCode}` }
                    loginWithGoogle(payload)
                    // if (data.error) {
                    //     toast.error(data.error.message)
                    // } else {
                    //     Cookies.set("GENTI_APP_TOKEN", data.data.access_token)
                    //     Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.data.user_detail))
                    //     reset();
                    //     const intent_url = localStorage.getItem('genti_intent_url')
                    //     if (intent_url) {
                    //         navigate(intent_url)
                    //     } else {
                    //         navigate('/explore')

                    //     }
                    // }

                },
                onError: (error) => {
                    if (error.response) return toast.error(error.response.data.error.message)
                    else if (error.data) return toast.error(error.data.message)
                    else return toast.error(error.message)

                }
            })

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: credentialResponse => {
            getGoogleDetails(credentialResponse.access_token)
        },
        onError: () => {
        },
    });



    const { mutate: loginWithFacebook, isLoading: fb_loading } = useMutation(Client.auth.facebook_login, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                Cookies.set("GENTI_APP_TOKEN", data.access_token)
                Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.user_detail))
                // reset();
                storeSignature({
                    device_signature
                })
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })

    const handleFacebookLogin = (values) => {
        // values.access_token = fb_access_token
        values.country_id = `+${userCallingCode}`
        loginWithFacebook(values)
    }


    const navigate = useNavigate()
    return (
        // <AuthLayout>
        <div className='register-form__container'>
            <div className='d-flex align-items-center justify-content-center pt-3 pb-5'>
                <div>
                    <div className='logo-wrapper text-center'>
                        <img src={logo} className='logo-img' alt='logo-img' />
                    </div>
                    <div className='form-wrapper'>
                        <form className='auth-form' autocomplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className='mb-3'>
                                <h3 className='form-title create-text'>Create Account</h3>
                            </div>
                            <div className='mb-4'>
                                {
                                    errors.name ? <p className='text-danger auth-label mb-0'>{errors.name?.message}</p> : <label className='auth-label'>Name</label>
                                }
                                <input type='' placeholder='e.g Abdusallam Robiat' className={`${errors.name ? 'border-danger' : ''} auth-input form-control w-100`} {...register("name")} />
                            </div>
                            <div className='mb-4'>
                                {
                                    errors.email ? <p className='text-danger auth-label mb-0'>{errors.email?.message}</p> : <label className='auth-label'>Email Address</label>
                                }
                                <input type='email' autocomplete="off" placeholder='account@email.com' className={`${errors.email ? 'border-danger' : ""} auth-input form-control w-100`} {...register("email")} />
                            </div>
                            {/* <div className='mb-4 '>

                                {errors.mobile_number ? <p className='text-danger auth-label mb-0'>{errors.mobile_number?.message}</p> : errors.country_id ? <p className='text-danger auth-label mb-0'>{errors.country_id?.message}</p> : <label className='auth-label'>Phone Number</label>}
                                <div className={`${errors.mobile_number || errors.country_id ? 'border-danger' : ""} d-flex phone-number-wrapper`}>
                                    <div className='d-flex align-items-center w-5' style={{
                                        paddingLeft: '13px',
                                        width: '80px'
                                    }}>
                                        <div className='text-white'>+</div>
                                        <select className='phone-input-select  w-100' style={{
                                            paddingRight: '0'
                                        }} {...register("country_id")}>
                                            {countryCode.map((el, i) => <option label={`${el?.code}`} value={`+${el?.code}`}>{`+${el?.code}`}</option>)}
                                        </select>
                                    </div>

                                    <span />
                                    <input type='phone'
                                        readOnly
                                        placeholder='Phone Number'
                                        onFocus={(e) => e.target.removeAttribute('readonly')}
                                        className='auth-input form-control w-75 border-set-right'
                                        {...register("mobile_number")}
                                    />
                                </div>
                            </div> */}
                            <Row className='g-4 mb-4'>
                                <Col sm='12' md='6'>
                                    <div className=''>
                                        {
                                            errors.dob ? <label className='text-danger auth-label'>{errors.dob?.message}</label> : <label className='auth-label'>Date of birth</label>
                                        }
                                        <input type='date' autocomplete="off" placeholder='' className={`${errors.dob ? 'border-danger' : ""} auth-input form-control w-100`} {...register("dob")} />
                                    </div>
                                </Col>
                                <Col sm='12' md='6'>
                                    <div className=''>
                                        {
                                            errors.gender ? <label className='text-danger auth-label'>{errors.gender?.message}</label> : <label className='auth-label'>Gender</label>
                                        }
                                        <Select
                                            className={`form-control cstm-react__select__wrapper w-100 ${errors.gender ? 'error_detected' : ""}`}
                                            placeholder='Select Option'
                                            classNamePrefix="cstm-react__select"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#8E75FF',
                                                    primary: '#121212',
                                                },
                                            })}
                                            options={genderOptions ?
                                                genderOptions.map(el => (
                                                    {
                                                        label: el?.title,
                                                        value: el?.id
                                                    }
                                                )
                                                ) : []
                                            }

                                            onChange={option => typeOnChange(option ? option.value : option)}
                                            {...restTypeField}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className='mb-5'>
                                {errors.password ? <p className='text-danger auth-label mb-0'>{errors.password?.message}</p> : <label className='auth-label' tabIndex={0}>Password</label>}

                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>
                                    <input
                                        type={`${showPassword ? 'text' : 'password'}`}
                                        className={`${errors.password ? 'border-danger' : ""} auth-input form-control w-100 `}
                                        readOnly onClick={(e) => e.target.removeAttribute('readonly')}
                                        {...register("password")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={togglePassword} role='button'>
                                        {/* {`${showPassword ? 'HIDE' : 'SHOW'}`} */}
                                        <img src={showPasswordIcon} alt='icon' />
                                    </div>
                                </div>
                            </div>


                            <div className='sign-in-btn'>
                                <button className='btn auth-btn w-100 ' type='submit' disabled={isLoading}>
                                    {
                                        isLoading ?
                                            <ButtonLoader /> :
                                            'Create Account'
                                    }

                                </button>
                            </div>
                            {
                                !ayobaCheck ? <div className='social-logins__wrapper'>
                                    <div className='strike'>
                                        <span>OR</span>
                                    </div>
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_APPID}
                                        scope=''
                                        fields='name,email'
                                        onSuccess={(response) => {
                                            fb_access_token.current = response.accessToken
                                            // setFBAccessToken(token => token = response.accessToken)
                                        }}
                                        onFail={(error) => {
                                        }}
                                        onProfileSuccess={(response) => {
                                            response.access_token = fb_access_token.current
                                            handleFacebookLogin(response)
                                        }}

                                        render={({ onClick, logout }) => (
                                            <div className='social-login-wrapper d-flex'
                                                onClick={onClick}
                                                onLogoutClick={logout}
                                            >
                                                <div className='social-login__logo'>
                                                    <img src={s1} alt='facebook' />
                                                </div>
                                                <p className='social-login_name mb-0 w-100 text-center'>Sign in with Facebook</p>

                                            </div>
                                            // <CustomComponent onClick={onClick} onLogoutClick={logout} />
                                        )}
                                    />
                                    <div className='social-login-wrapper d-flex'
                                        onClick={() => {
                                            handleGoogleLogin()
                                        }}
                                    >
                                        <div className='social-login__logo'>
                                            <img src={s3} alt='google' />
                                        </div>
                                        <p className='social-login_name mb-0 w-100 text-center'>Continue with Google</p>

                                    </div>
                                    {/* <div className='social-login-wrapper d-flex'>
                                     <div className='social-login__logo'>
                                         <img src={s2} alt='facebook' />
                                     </div>
                                     <p className='social-login_name mb-0 w-100 text-center'>Continue with Apple</p>
 
                                 </div> */}

                                </div> : null
                            }

                            <div>
                                <p className='auth-text'>
                                    Already have account?  <NavLink to={'/auth/login'} className='auth-link'>Sign In</NavLink>
                                </p>
                            </div>

                        </form>
                    </div>

                </div>

            </div>
        </div>


        // </AuthLayout>
    )
}

export default Login