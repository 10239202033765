import React from 'react'
import Pack from '../Pack'


const CoinsPack = ({ coinsPack }) => {
    return (
        <div className='coin-pack__container'>
            <h3 className='pack-title'>Coins Pack</h3>
            {
                coinsPack?.map((el, i) =>
                    <>
                        <Pack pack={el} />
                        {
                            i !== coinsPack?.length - 1 &&
                            <div className='pack-divider' />

                        }

                    </>


                )
            }
        </div>
    )
}

export default CoinsPack