import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AppLayout from '../../layout/App'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';

const ProtectedeRoute = () => {

    const intent_url = window.location.pathname;

    const accessToken = Cookies.get("GENTI_APP_TOKEN")
    let authorized = false
    if (accessToken) {
        let decoded = jwt_decode(accessToken)
        if (decoded.exp * 1000 < Date.now()) {
            authorized = false
        } else {
            const stored_url = localStorage.getItem('genti_intent_url')
            if (stored_url) {
                localStorage.removeItem('genti_intent_url')
            }
            authorized = true
        }
    } else {
        localStorage.setItem('genti_intent_url', intent_url)
        authorized = false
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return authorized ? (
        // <AppLayout>
        <Outlet />
        // </AppLayout>
    ) : (
        <Navigate to='/auth/login' />
    )
}



export default ProtectedeRoute