import React from 'react'
import { Modal } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

const Paywall = ({ show, toggle }) => {
    const navigate = useNavigate()
    return (
        <Modal isOpen={show} toggle={toggle} className='modal-dialog-centered modal-sm paywall-modal' >
            <div className='d-flex align-items-center justify-content-center px-3 py-4'>
                <div>
                    <p onClick={toggle} className='paywall-guard__text text-right d-flex justify-content-end' role='button' >Cancel</p>

                    <p className='paywall-guard__text my-4'>You need to be on any premium plan to listen to this audio</p>
                    <button className='get-app-btn btn go-premium-btn w-100' onClick={() => {
                        navigate('/subscription')
                    }}>Go premium</button>
                </div>
            </div>


        </Modal>

    )
}

export default Paywall