import React, { useState, useRef, useEffect } from 'react'
import AuthLayout from '../../layout/Auth'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidation } from '../../utils/form_validation';
import { useMutation } from 'react-query';
import Client from '../../client'
import { toast } from 'react-toastify';
import ButtonLoader from '../../component/ButtonLoader';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import s1 from '../../assets/img/s1.svg'
import s2 from '../../assets/img/s2.svg'
import s3 from '../../assets/img/s3.svg'
import showPasswordIcon from '../../assets/img/showPassword.svg'
import logo from '../../assets/img/newLogo.svg'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { isLoadedFromAyoba } from '../../utils/Ayobacheck';
import { getDeviceSignature } from '../../utils/deviceSignature';





const Login = () => {
    const [device_signature, setDeviceSignature] = useState(null)


    const fetchData = async () => {
        try {
            const signature = await getDeviceSignature();
            setDeviceSignature(signature);
        } catch (error) {
            console.error('Error fetching device signature:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])

    const ayobaCheck = isLoadedFromAyoba()
    const navigate = useNavigate()
    const fb_access_token = useRef(null)

    const [showPassword, setShowPassword] = useState(false)
    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(loginValidation),
    });



    const { mutate: storeSignature, isLoading: storing } = useMutation(Client.auth.store_device_signature, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {

                const intent_url = localStorage.getItem('genti_intent_url')
                if (intent_url) {
                    navigate(intent_url)
                } else {
                    navigate('/')

                }
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })


    const { mutate: loginUser, isLoading } = useMutation(Client.auth.login, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                Cookies.set("GENTI_APP_TOKEN", data.data.access_token)
                Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.data.user_detail))
                reset();
                storeSignature({
                    device_signature
                })
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })

    const { mutate: loginWithGoogle, isLoading: is_loading } = useMutation(Client.auth.google_login, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                Cookies.set("GENTI_APP_TOKEN", data.access_token)
                Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.user_detail))
                reset();
                storeSignature({
                    device_signature
                })
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })


    const { mutate: getGoogleDetails, isLoading: loading } =
        useMutation((token) => axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        }),
            {
                onSuccess: (data, variables, context) => {
                    const { name, email } = data.data
                    const payload = { name, email, access_token: variables }
                    loginWithGoogle(payload)
                    // if (data.error) {
                    //     toast.error(data.error.message)
                    // } else {
                    //     Cookies.set("GENTI_APP_TOKEN", data.data.access_token)
                    //     Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.data.user_detail))
                    //     reset();
                    //     const intent_url = localStorage.getItem('genti_intent_url')
                    //     if (intent_url) {
                    //         navigate(intent_url)
                    //     } else {
                    //         navigate('/explore')

                    //     }
                    // }

                },
                onError: (error) => {
                    if (error.response) return toast.error(error.response.data.error.message)
                    else if (error.data) return toast.error(error.data.message)
                    else return toast.error(error.message)

                }
            })



    const handleGoogleLogin = useGoogleLogin({
        onSuccess: credentialResponse => {
            getGoogleDetails(credentialResponse.access_token)
        },
        onError: () => {
        },
    });






    const { mutate: loginWithFacebook, isLoading: fb_loading } = useMutation(Client.auth.facebook_login, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                Cookies.set("GENTI_APP_TOKEN", data.access_token)
                Cookies.set("GENTI_APP_CURRENT_USER", JSON.stringify(data.user_detail))
                reset();
                storeSignature({
                    device_signature
                })
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })

    const handleFacebookLogin = (values) => {
        // values.access_token = fb_access_token
        loginWithFacebook(values)
    }




    const onSubmitHandler = (data) => {

        loginUser(data)
    };


    return (
        // <AuthLayout>
        <div className='register-form__container'>
            <div className='d-flex align-items-center justify-content-center pt-3 pb-5'>
                <div>
                    <div className='logo-wrapper text-center'>
                        <img src={logo} className='logo-img' alt='logo-img' />
                    </div>
                    <div className='form-wrapper'>
                        <form className='auth-form' onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className='mb-3'>
                                <h3 className='form-title sign-text'>Sign In</h3>
                            </div>
                            <div className='mb-3'>
                                {
                                    errors.email ? <p className='text-danger auth-label mb-0'>{errors.email?.message}</p> : <label className='auth-label'>Email Address</label>
                                }
                                <input
                                    // type='email'
                                    placeholder='account@email.com'
                                    className={`auth-input form-control w-100 ${errors.email ? 'border-danger' : ''} `}
                                    {...register("email")}
                                />
                            </div>
                            <div className='mb-2'>
                                {errors.password ? <p className='text-danger auth-label mb-0'>{errors.password?.message}</p> : <label className='auth-label' tabIndex={0}>Password</label>}
                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>

                                    <input
                                        type={`${showPassword ? 'text' : 'password'}`}
                                        className={`auth-input form-control w-100 ${errors.password ? 'border-danger' : ''}`}
                                        {...register("password")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={togglePassword} role='button'>
                                        <img src={showPasswordIcon} alt='icon' />
                                        {/* {`${showPassword ? 'HIDE' : 'SHOW'}`} */}
                                    </div>
                                </div>
                            </div>
                            <div className=' w-100 forgot-password-link'>
                                <Link to={'/auth/forgot-password'} className='auth-link '>Forgot Password?</Link>
                            </div>

                            <div className='sign-in-btn'>
                                <button className='btn auth-btn w-100 ' type='submit' disabled={isLoading}>
                                    {
                                        isLoading || storing ?
                                            <ButtonLoader /> :
                                            'Sign In'
                                    }


                                </button>
                            </div>

                            {
                                !ayobaCheck ? <div className='social-logins__wrapper'>
                                    <div className='strike'>
                                        <span>OR</span>
                                    </div>

                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_APPID}
                                        scope=''
                                        fields='name,email'
                                        onSuccess={(response) => {
                                            fb_access_token.current = response.accessToken
                                            // setFBAccessToken(token => token = response.accessToken)
                                        }}
                                        onFail={(error) => {
                                        }}
                                        onProfileSuccess={(response) => {
                                            response.access_token = fb_access_token.current
                                            handleFacebookLogin(response)
                                        }}

                                        render={({ onClick, logout }) => (
                                            <div className='social-login-wrapper d-flex'
                                                onClick={onClick}
                                                onLogoutClick={logout}
                                            >
                                                <div className='social-login__logo'>
                                                    <img src={s1} alt='facebook' />
                                                </div>
                                                <p className='social-login_name mb-0 w-100 text-center'>Sign in with Facebook</p>

                                            </div>
                                            // <CustomComponent onClick={onClick} onLogoutClick={logout} />
                                        )}
                                    />

                                    <div className='social-login-wrapper d-flex'
                                        onClick={() => {
                                            handleGoogleLogin()
                                        }}>
                                        <div className='social-login__logo'>
                                            <img src={s3} alt='google' />
                                        </div>
                                        <p className='social-login_name mb-0 w-100 text-center'>Sign in with Google</p>

                                    </div>
                                    {/* <div className='social-login-wrapper d-flex'>
                                    <div className='social-login__logo'>
                                        <img src={s2} alt='facebook' />
                                    </div>
                                    <p className='social-login_name mb-0 w-100 text-center'>Sign in with Apple</p>

                                </div> */}

                                </div> : null
                            }



                            <div>
                                <p className='auth-text'>
                                    New to Genti?  <Link to={'/auth/register'} className='auth-link '>Create Account</Link>
                                </p>
                            </div>

                            {/* <div>
                                <p className='auth-text'>
                                    New to Genti?  <Link to={'/auth/register'} className='auth-link'>Create Account</Link>
                                </p>
                            </div> */}

                        </form>
                    </div>


                </div>
            </div>
        </div>


        // </AuthLayout> 
    )

}

export default Login