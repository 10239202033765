import React, { useState } from 'react'
import close from '../../../assets/img/closeSidebar.svg'
import logo from '../../../assets/img/logo2.svg'
import user from '../../../assets/img/User.svg'
import SidebarMenu from './menu'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';
import { RiLogoutCircleRLine } from 'react-icons/ri'


const MobileSidebar = ({ show, toggle }) => {
    const [activeTab, setActiveTab] = useState('')
    const navigate = useNavigate()
    const getInitials = (fullName) => {
        const allNames = fullName.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
        return initials;
    }

    const currentUser = Cookies.get("GENTI_APP_CURRENT_USER") ? JSON.parse(Cookies.get("GENTI_APP_CURRENT_USER")) : null
    const accessToken = Cookies.get("GENTI_APP_TOKEN")

    let is_authorized = false
    if (accessToken) {
        let decoded = jwt_decode(accessToken)
        if (decoded.exp * 1000 < Date.now()) {
            is_authorized = false
        } else {
            is_authorized = true
        }
    } else {
        is_authorized = false
    }

    return (
        <div className={`d-lg-none d-block mobile-side__bar__wrapper ${show ? 'show' : ''}`}>
            <div className={`d-lg-none d-block mobile-side__bar `}>
                <div className='d-flex align-items-center justify-content-end mb-3' onClick={toggle}>
                    <img src={close} />
                </div>
                <div className='mobile-sidebar__wrapper'>
                    <div className=''>
                        <img src={logo} alt='genti' />
                    </div>
                    <div className='user-details-container no-border-bottom'>
                        {
                            is_authorized ?
                                <div className='unauthorized-wrapper  d-flex align-items-center' role='button' onClick={() => {
                                    navigate('/profile')
                                }}>
                                    <div className='authorized-wrapper__initials-container d-flex align-items-center justify-content-center'>
                                        {getInitials(currentUser.name)}
                                    </div>
                                    <p className='login-text mb-0'>
                                        {currentUser.name}
                                    </p>
                                </div> : <>
                                    <div className='unauthorized-wrapper d-flex align-items-center' role='button' onClick={() => {
                                        navigate('/auth/login')
                                    }}>
                                        <div className='unauthorized-wrapper__img-container d-flex align-items-center justify-content-center'>
                                            <img src={user} alt='unauthorized-wrapper__img' />
                                        </div>
                                        <p className='login-text mb-0'>
                                            Login/Signup
                                        </p>
                                    </div>
                                </>
                        }

                    </div>
                    <div className='sidebar-menu-wrapper px-2'>

                        {SidebarMenu.map((el, i) =>
                            <NavLink to={`/${el.link}`} key={i}
                                // onClick={() => {
                                //     setActiveTab(el.link)
                                // }}
                                className={({ isActive, isPending }) => {
                                    if (isActive) setActiveTab(el.link)
                                    return isPending ? "pending" : isActive ? "menu-link active" : "menu-link"
                                }
                                }
                            >
                                <div className={`d-flex align-items-center px-3 mb-4  `}>
                                    <div>
                                        {activeTab === (`${el.link}`) || activeTab === (`/${el.link}`) ?

                                            <img src={el.icon} alt='icon' /> :
                                            <img src={el.inactiveIcon} alt='icon' />}
                                    </div>
                                    <p className='menu-label mb-0'>{el.name}</p>
                                </div>

                            </NavLink>
                        )}

                    </div>
                </div>
            </div>
            {
                is_authorized &&

                <div className='position-absolute logout-section pb-4' role='button'>
                    <div className='d-flex align-items-center'>
                        <RiLogoutCircleRLine className='text-danger' />
                        <p className='text-danger mb-0 px-2' onClick={() => {
                            Cookies.remove('GENTI_APP_TOKEN')
                            Cookies.remove('GENTI_APP_CURRENT_USER')
                            navigate('/auth/login')
                        }}>Logout</p>
                    </div>

                </div>
            }

        </div>

    )
}

export default MobileSidebar