import React from 'react'
import './button.css'
import playIcon from '../../../../assets/img/play.svg'
import pauseIcon from '../../../../assets/img/pause.svg'
import back from '../../../../assets/img/back.svg'
import forwardIcon from '../../../../assets/img/forward.svg'



function Button({ play, isPlaying, forward,
  backward }) {
  return (
    <div className='btn-container'>
      <div role='button' onClick={backward}>
        <img src={forwardIcon} alt='play-ctrl' />
      </div>
      <div onClick={play} role='button' className='play-ctrl-btn'>
        {
          !isPlaying ?
            <img src={playIcon} alt='play-ctrl' />
            :
            <img src={pauseIcon} alt='play-ctrl' />

        }
      </div>
      <div role='button' onClick={forward}>
        <img src={back} alt='play-ctrl' />
      </div>
    </div>
  )
}
export default Button
