import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify';
import Router from './router'
import 'react-toastify/dist/ReactToastify.css';
import './assets/style'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/font/index.css'
import './App.css';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import "flickity/css/flickity.css";

import { GoogleOAuthProvider } from '@react-oauth/google';


const queryClient = new QueryClient()

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
    >
      <QueryClientProvider client={queryClient}>

        {/* <div className="App"> */}
        <Router />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" />
        {/* </div> */}
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
