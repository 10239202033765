import searchIcon from '../../../assets/img/activeSearch.svg'
import inactivetIcon from '../../../assets/img/inSearch.svg'
import inactiveSub from '../../../assets/img/inactiveSub.svg'
import activeSubIcon from '../../../assets/img/activeSub.svg'
import promo from '../../../assets/img/promo.svg'
import activePromo from '../../../assets/img/activePromo.svg'
import activeCoin from '../../../assets/img/activeCoin.svg'



const SidebarMenu = [
    // {
    //     name: 'Explore',
    //     icon: searchIcon,
    //     inactiveIcon: inactivetIcon,
    //     link: ''
    // },
    // {
    //     name: 'Subscription',
    //     icon: activeSubIcon,
    //     inactiveIcon: inactiveSub,
    //     link: 'subscription'
    // },
    {
        name: 'Coin',
        icon: activeCoin,
        inactiveIcon: activeCoin,
        link: 'coin'
    },
    // {
    //     name: 'Promos & Referral',
    //     icon: promo,
    //     inactiveIcon: activePromo,
    //     link: 'promos-and-referral'
    // }
]

export default SidebarMenu