import React, { useEffect } from 'react'
import coinIcon from '../../../assets/img/activeCoin.svg'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import GetTrialCoinManager from '../../../client/coins/GetTrialCoin';
import ButtonLoader from '../../../component/ButtonLoader';

const Pack = ({ pack }) => {
    const { getTrialCoin, isLoading, isSuccess } = GetTrialCoinManager()
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (isSuccess) {
    //         navigate('/coin/balance')
    //     }
    // }, [isSuccess])

    return (
        <div className='d-block d-md-flex align-items-center justify-content-between flex-wrap'>
            <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center pack-details_wrapper flex-column justify-content-center'>
                    <img src={coinIcon} alt='icon' style={{
                        height: '32px',
                        width: '32px',
                        marginBottom: '8px'
                    }} />
                    <p className='pack-value mb-0'>{`${pack?.unit ?? 0} coins`}</p>
                </div>
                <div>
                    <p className='coin-pack_title mb-0'>{pack?.title}</p>
                    <div className='d-none d-md-flex'>
                        <p className=' coin-pack__amount my-1'>{pack?.amount_web === 0 ? 'Free' : `${pack?.currency_group === 2 ? '₦' : '$'}${pack?.amount_web?.toLocaleString() ?? 0}`}</p>

                    </div>

                    <div className='d-block d-md-none'>
                        <p className='coin-pack__amount my-1'>{pack?.amount_web === 0 ? 'Free' : `${pack?.currency_group === 2 ? '₦' : '$'}${pack?.amount_web?.toLocaleString() ?? 0}`}</p>

                    </div>
                    <p className=' text-white  mb-0'>{pack?.bonus === 0 ? '' : `+${pack?.bonus?.toLocaleString()} free coins`}</p>

                    <p className='coin-pack_description mb-0 d-none d-md-block'>{pack?.description}</p>
                </div>


            </div>
            {
                pack?.description?.length > 0 && <p className='coin-pack_description my-1 d-block d-md-none'>{pack?.description}</p>
            }

            <button
                disabled={isLoading}
                className='btn coin-cta__btn flex-wrap_mobile'
                onClick={() => {
                    if (pack?.amount_web === 0) {
                        getTrialCoin()
                    } else {
                        Cookies.set('selected_pack', JSON.stringify(pack))
                        // //console.log(pack)
                        navigate('/coin/payment')
                    }

                }}
            >
                {
                    isLoading ? <ButtonLoader /> : <>
                        {
                            pack?.amount_web !== 0 ? 'Buy Now' : 'Get Started'
                        }
                    </>
                }
            </button>
        </div>)
}

export default Pack