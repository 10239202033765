import React from 'react'
import authBg from '../../assets/img/authbg.png'
import logo from '../../assets/img/newLogo.svg'


const Layout = ({ children }) => {
    return (
        <div className='auth-layout d-flex justify-content-center align-items-center'>
            <div className='auth-bg-img ' >
                <img src={authBg} alt='bg-img' className='bg-img text-center w-100' />
            </div>
            <div className='auth-content-wrapper'>
                <div>
                    <div className='logo-wrapper text-center'>
                        <img src={logo} className='logo-img' alt='logo-img' />
                    </div>
                    <div className='form-wrapper'>
                        {children}

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Layout