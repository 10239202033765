import React, { useState, useEffect } from 'react'
import AuthLayout from '../../layout/Auth'
import OtpForm from '../../component/OTP'
import arrowIcon from '../../assets/img/arrow.svg'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import Client from '../../client'
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ButtonLoader from '../../component/ButtonLoader';



const OTP = () => {
    const userEmail = Cookies.get("GENTI_RESET_EMAIL")
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [secondsLeft, setSecondsLeft] = useState(60)
    const [resend, setResend] = useState(false)

    const { mutate: requestOTP, isLoading } = useMutation(Client.auth.request_otp, {
        onSuccess: async (data) => {
        },
        onError: (error) => {
            if (error.data) toast.error(error.data.message)
            toast.error(error.message)

        }
    })
    // eslint-disable-next-line
    const { mutate: verifyOTP, isLoading: verifying } = useMutation(Client.auth.verify_otp, {
        onSuccess: () => {
            navigate('/auth/reset-password')
        },
        onError: (error) => {
            if (error.response) toast.error(error.response.data.error.message)
            else if (error.data) toast.error(error.data.message)
            else toast.error(error.message)

        }
    })


    const handleOTPVerification = () => {
        // eslint-disable-next-line
        const data = {
            email: userEmail,
            code: otp,
        }
        Cookies.set("GENTI_RESET_CODE", otp)

        navigate('/auth/reset-password')
    }


    useEffect(() => {
        if (secondsLeft > 0) {
            setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
        } else {
            setResend(true)

        }
    }, [secondsLeft])




    return (
        <AuthLayout>
            <form className='auth-form position-relative mobile-form' autocomplete='off' >
                <div className='mb-3 '>
                    <h3 className='form-title'>Enter OTP</h3>
                </div>
                <div className='text-center '>
                    <p className='otp-text mb-0'>Please enter the 4-digit code sent to</p>
                    <p className='verify-email'>{userEmail}</p>
                </div>

                <div className='otp-verify-wrapper'>
                    <OtpForm otp={otp} setOtp={setOtp} />
                </div>


                <div className='sign-in-btn' type='submit'>
                    <button className='btn auth-btn w-100' disabled={isLoading || verifying || otp.length < 4} onClick={(e) => {
                        e.preventDefault()
                        // setVerificationSuccess(true)
                        handleOTPVerification()
                    }}>
                        {
                            verifying ?
                                <ButtonLoader /> :
                                'Confirm'
                        }

                    </button>
                </div>

                <div>
                    {
                        resend ? <p role='button' className='verify-email' onClick={() => {
                            const data = {
                                email: userEmail
                            }
                            setResend(false)
                            setSecondsLeft(60)
                            requestOTP(data)
                        }}>
                            Resend OTP
                        </p> : <p className='resend-otp-text'>
                            Resend OTP in  <span className='resend-countdown'>{`${secondsLeft}s`}</span>
                        </p>
                    }

                </div>

                <div className='back-icon d-flex align-items-center justify-content-center position-absolute' role='button' onClick={() => {
                    navigate('/auth/forgot-password')
                }}>
                    <img src={arrowIcon} alt='arrow' className='arrow-icon' />
                </div>

            </form>

        </AuthLayout>
    )
}

export default OTP