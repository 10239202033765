import React, { useState } from 'react'
import UTM from '../../utils/UTM'
import searchIcon from '../../assets/img/searchIcon.svg'
import { useNavigate } from 'react-router-dom'
import ham from '../../assets/img/ham.svg'
import logo from '../../assets/img/logo2.svg'
import MobileSidebar from './Sidebar/MobileSidebar'
import { useQuery } from 'react-query';
import Client from '../../client'
import useDebounce from '../../component/Hooks/Debounce'
import Paywall from '../../component/Titles/Paywall'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';

const Header = () => {
    const navigate = useNavigate()
    const [showSidebar, setShowSidebar] = useState(false)
    const [userCurrentPlan, setUserCurrentPlan] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])
    const debouncedSearchValue = useDebounce(searchValue, 1000)
    const accessToken = Cookies.get("GENTI_APP_TOKEN")

    let is_authorized = false
    if (accessToken) {
        let decoded = jwt_decode(accessToken)
        if (decoded.exp * 1000 < Date.now()) {
            is_authorized = false
        } else {
            is_authorized = true
        }
    } else {
        is_authorized = false
    }


    const [showPaywall, setPaywall] = useState(false)

    const togglePaywall = () => {
        setPaywall(!showPaywall)
    }

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }



    useQuery(['user_plans'], () => Client.subscription.user_plans(), {
        enabled: is_authorized,
        onSuccess: (data) => {
            setUserCurrentPlan(data.data.active_subscription)
            // setSubscriptions(data.data.result)
        }
    })

    useQuery(
        ['search-result', { debouncedSearchValue }],
        () => Client.home.search_titles({
            query: debouncedSearchValue
        }),
        {
            enabled: debouncedSearchValue.length > 0,
            onSuccess: (data) => {
                setSearchResult(data.result)
            },
        }
    )

    return (
        <>
            <header className='d-none d-lg-flex justify-content-between position-fixed  px-5 align-items-center flex-wrap'>
                <div className='d-none explore-search__content-wrapper position-relative'>
                    <div className='explore-search__content_icon__wrapper'>
                        <img src={searchIcon} alt='search_icon' />
                    </div>
                    <input className='explore-search__content-input' placeholder='Search podcast, audio stories and more'
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {
                        debouncedSearchValue.length > 0 && searchResult.length > 0 &&
                        <div className='search-result-container position-absolute'>
                            {searchResult.map((el, i) =>
                                <div key={i} role='button'
                                    onClick={() => {
                                        navigate(el?.type_id === 3 ? `/title/${el.id}?type=podcast` : `/title/${el.id}`)


                                        // if (el?.membership_type === 0) {
                                        //     navigate(`/explore/player/${el?.id}`)
                                        // } else {
                                        //     if (userCurrentPlan === 0) {
                                        //         togglePaywall()
                                        //     } else {
                                        //         navigate(`/explore/player/${el?.id}`)
                                        //     }
                                        // }
                                    }}
                                // onClick={() => {

                                //     navigate(`/explore/player/${el?.id}`)
                                // }}
                                >
                                    <p className={`search-result__data  ${i === searchResult.length - 1 ? 'last' : ''}`}>
                                        <span className='search-result_count'>{i + 1}</span>
                                        {el?.title}
                                    </p>
                                </div>
                            )}
                        </div>
                    }

                </div>
                <div className='d-flex justify-content-end  w-100'>
                    {
                        // userCurrentPlan === 0 && !window.location.href.includes('subscription') ? <button className='get-app-btn btn go-premium-btn' onClick={() => {
                        //     navigate('/subscription')
                        // }}>Go premium</button> : null

                    }

                    <button className='get-app-btn btn explore-section' onClick={UTM}>Get The App</button>
                </div>

                <Paywall show={showPaywall} toggle={togglePaywall} />

            </header>

            <header className='d-flex d-lg-none justify-content-between position-fixed  px-3 align-items-center mobile-header'>
                <div className='d-flex align-items-center'>
                    <div onClick={toggleSidebar}>
                        <img src={ham} alt='icon' />
                    </div>
                    <div className='mobile-logo__wrapper'>
                        <img src={logo} className='mobile-logo' />
                    </div>
                </div>
                {/* <div >
                    {
                        userCurrentPlan !== null || userCurrentPlan !== [] || window.location.href.includes('subscription') ? <button className='get-app-btn btn explore-section' onClick={UTM}>Get The App</button> :
                            <button className='get-app-btn btn go-premium-btn' onClick={() => {
                                navigate('/subscription')
                            }}>Go premium</button>
                    }

                </div> */}

            </header>

            <MobileSidebar
                show={showSidebar}
                toggle={toggleSidebar}
            />
        </>

    )
}
export default Header